.game {
  display: flex;
  min-height:100vh;
  max-width: 100vw;
  flex-direction: column;
  align-items: center;
  opacity: 0;
  -webkit-animation: 1s fade 0s forwards;
          animation: 1s fade 0s forwards;
}

.tile {
  flex: 0 0 auto;
  width: 50px;
  height: 50px;
  padding: 0px;
  margin-right: 2px;
  margin-left: 2px;
  border: none;
  border-radius: 4px;
  background-color: #14F3FF;
  font-size: 25px;
  cursor: pointer;
}

.tile.hidden {
  color: #45a29e;
  background-color: #45a29e;
}

.tile.hidden.flagged {
  color: #1f2833;
}

.tile.bomb {
  background-color: red;
  color: black;
}

.tile.zeroAdjacent {
  color: #14F3FF;
}

.tile.oneAdjacent {
  color: blue;
}

.tile.twoAdjacent {
  color: green;
}

.tile.threeAdjacent {
  color: red;
}

.tile.fourAdjacent {
  color: purple;
}

.tile.fiveAdjacent {
  color: maroon;
}

.tile.sixAdjacent {
  color: #008385;
}

.tile.sevenAdjacent {
  color: black;
}

.tile.eightAdjacent {
  color: grey;
}

.tile:active {
  filter: saturate(150%);
}

.board {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  height: 100%;
  max-width: 100vw;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 80px;
}

.board-rows {
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 150px;
}

.board-row {
  display: flex;
  justify-content: flex-start;
  height: 50px;
  margin: 2px;
  padding: 0px;
}

.board-ui {
  position: fixed;
  top: 0;
  width: 100vw;
  display: flex;
  align-self: center;
  justify-content: center;
  margin: 50px 0px 10px;
  padding: 10px;
  background-color: #1f2833;
}

.board-ui.flagMode {
  background-color: #57708e;
}

.flagMode button{
  border: 2px solid #1f2833;
}

.btn {
  flex-basis: auto;
  width: 102px;
  height: 50px;
  padding: 0px;
  margin: 1px;
  border-radius: 4px;
  color: #1f2833;
  background-color: #45a29e;
  font-size: 30px;
  border: solid 1px transparent;
  cursor: pointer;
}

.btn:active {
  transform: translateY(1px);
  filter: saturate(150%);
}

.btn:hover {
  background-color: #14F3FF;
  border-color: currentColor;
}

.btn.timer, .btn.difficulty-button {
  width: 206px;
}

.message-display {
  flex: 1 1 auto;
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  max-width: 100vw;
  width: 520px;
  margin: 10px 2px;
  padding: 10px;
  border-radius: 4px;
  background-color: #1f2833;
  color: #14F3FF;
  text-align: center;
}

.message-display i.toggle:hover {
  color: white;
  cursor: pointer;
}

.message-display.tutorial {
  height: 100vh;
  width: 100vw;
  padding: 0;
  margin: 0;
}

.tutorial {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.tutorial img {
  max-width: 100%;
  padding: 10px;
}

.tutorial p {
  padding: 20px;
  max-width: 772px;
}

@media (max-width: 500px) {
  .board {
    align-items: flex-start;
  }

  .btn {
    font-size: 1.4rem;
    height: 40px;
  }

  .tile {
    height: 40px;
    width: 40px;
  }

  .board-row {
    height: 40px;
  }

  .message-display {
    font-size: 1.4rem;
  }
} 

@-webkit-keyframes fade {
  100% {
    opacity: 1;
  }
}

@keyframes fade {
  100% {
    opacity: 1;
  }
}