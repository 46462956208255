/* Navbar Styles */

#Navbar {
  position: fixed;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
    -ms-flex-pack: justify;
      justify-content: space-between;
  -webkit-box-align: center;
    -ms-flex-align: center;
      align-items: center;
  font-family: "Comfortaa";
  height: 3.2rem;
  z-index: 10;
  color: #66fcf1;
}

#NavbarBackground {
  position: absolute;
  top: 0;
  left:0;
  height: 100%;
  width: 100%;
  background-color: #0b0c10;
}

#Navbar a {
  z-index: 1;
  font-size: 1.3rem;
  margin: 0 1rem;
  max-width: 100%;
  max-height: 100%;
}

.NavHome {
  font-size: 1.8rem;
}

#Navbar nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

@media (max-width: 500px) {

  #Navbar {
    display: flex;
    overflow-x: auto;
    justify-content: center;
  }

  #Navbar nav {
    flex: 0 0 auto;
  }
  #Navbar a {
    margin: 0 0.5rem;
    font-size: 1rem;
    flex: 0 0 auto;
  }
}