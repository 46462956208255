.ContactContent {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
    -ms-flex-direction: column;
      flex-direction: column;
  -webkit-box-align: center;
    -ms-flex-align: center;
      align-items: center;
  -webkit-box-pack: justify;
    -ms-flex-pack: justify;
      justify-content: space-between;
  width: 100%;
}

.ContactContent p {
  margin-bottom: 2rem;
}

.ContactEmail {
  font-size: 1.4rem;
}

.ContactEmail i {
  margin-right: 1rem;
}

.SocialLinks {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
    -ms-flex-direction: row;
      flex-direction: row;
  width: 50%;
  -webkit-box-pack: center;
    -ms-flex-pack: center;
      justify-content: center;
}

.SocialLinks li {
  -webkit-box-flex: 1;
    -ms-flex: 1 0 auto;
      flex: 1 0 auto;
  -ms-flex-item-align: auto;
    -ms-grid-row-align: auto;
      align-self: auto;
  text-align: center;
  margin: 0.2rem;
}