/* Projects Styles */


.Projects {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  -webkit-box-pack: center;
    -ms-flex-pack: center;
        justify-content: center;
}

.Project {
  position: relative;
  max-width: 30vw;
  -webkit-box-flex: 1;
    -ms-flex: 1 1 30%;
        flex: 1 1 30%;
  margin: 2rem;
}

.ProjectCategoryIcon {
  display: flex;
  position: relative;
  -webkit-box-pack: center;
    -ms-flex-pack: center;
      justify-content: center;
  text-align: center;
  font-size: 6rem;
  -webkit-transition: -webkit-transform .15s ease-in-out;
  transition: -webkit-transform .15s ease-in-out;
  -o-transition: transform .15s ease-in-out;
  transition: transform .15s ease-in-out;
  transition: transform .15s ease-in-out, -webkit-transform .15s ease-in-out;
}

.ProjectCategoryIcon:hover {
  -webkit-transform: scale(1.1);
      -ms-transform: scale(1.1);
          transform: scale(1.1);
  -webkit-transition: -webkit-transform .25s ease-in-out;
  transition: -webkit-transform .25s ease-in-out;
  -o-transition: transform .25s ease-in-out;
  transition: transform .25s ease-in-out;
  transition: transform .25s ease-in-out, -webkit-transform .25s ease-in-out;
}

.Project ul li{
  margin: 0.5rem;
  list-style-type: square;
}

.ProjectTitle {
  margin: 2rem 0;
  text-align: center;
}

.ProjectTitle h3 {
  margin: .8rem 0;
  font-size: 2rem;
}

.ProjectTitle h4 {
  font-size: 1.7rem;
  color: white;
}

.ProjectDisplay {
  position: relative;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 1;
          flex: 1 1 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
    -ms-flex-pack: center;
      justify-content: center;
  -webkit-box-align: center;
    -ms-flex-align: center;
      align-items: center;
  overflow: hidden;
  max-width: 100%;
  max-height: 100%;
  text-align: center;
}

.ProjectDisplay img {
  max-width: 100%;
  max-height: 100%;
  top: 0;
  left: 0;
}

.ProjectDisplay i {
    -webkit-box-flex: 1;
        -ms-flex: 1 1 1;
            flex: 1 1 1;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    border-radius: 50%;
    opacity: 0;
    font-size: 2rem;
    text-align: center;
    color: #000000;
    width: 3.75rem;
    height: 3.75rem;
    line-height: 3.75rem;
    background: #ffffff;
    -webkit-transform: scale(0);
        -ms-transform: scale(0);
            transform: scale(0);
    -webkit-transition: -webkit-transform 300ms 0ms cubic-bezier(0.6, -0.28, 0.735, 0.045);
    transition: -webkit-transform 300ms 0ms cubic-bezier(0.6, -0.28, 0.735, 0.045);
    -o-transition: transform 300ms 0ms cubic-bezier(0.6, -0.28, 0.735, 0.045);
    transition: transform 300ms 0ms cubic-bezier(0.6, -0.28, 0.735, 0.045);
    transition: transform 300ms 0ms cubic-bezier(0.6, -0.28, 0.735, 0.045), -webkit-transform 300ms 0ms cubic-bezier(0.6, -0.28, 0.735, 0.045);
    color: #45a29e;
}

.ProjectDisplay a {
  display: block;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  position: absolute;
  font-size: 0;
}

.ProjectDisplay * {
  -webkit-transition: all .35s ease-in-out;
  -o-transition: all .35s ease-in-out;
  transition: all .35s ease-in-out;
}

.ProjectDisplay:hover i {
  -webkit-transform: scale(1);
      -ms-transform: scale(1);
          transform: scale(1);
  -webkit-transition: -webkit-transform 300ms 100ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: -webkit-transform 300ms 100ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -o-transition: transform 300ms 100ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: transform 300ms 100ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: transform 300ms 100ms cubic-bezier(0.175, 0.885, 0.32, 1.275), -webkit-transform 300ms 100ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  opacity: 1;
}

.ProjectDisplay:hover img{
  opacity: 0.3;
  -webkit-filter: grayscale(100);
          filter: grayscale(100);
}

.GithubLink {
  font-size: 2.5rem;
  margin-top: 5rem;
  -webkit-transition: -webkit-transform .15s ease-in-out;
  transition: -webkit-transform .15s ease-in-out;
  -o-transition: transform .15s ease-in-out;
  transition: transform .15s ease-in-out;
  transition: transform .15s ease-in-out, -webkit-transform .15s ease-in-out;
}

.GithubLink:hover {
  -webkit-transform: scale(1.1);
      -ms-transform: scale(1.1);
          transform: scale(1.1);
  -webkit-transition: -webkit-transform .25s ease-in-out;
  transition: -webkit-transform .25s ease-in-out;
  -o-transition: transform .25s ease-in-out;
  transition: transform .25s ease-in-out;
  transition: transform .25s ease-in-out, -webkit-transform .25s ease-in-out;
}

@media (max-width: 800px) {
    
  .Projects {
    max-width: 100vw;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }

  .Project {
    min-width: 100vw;
  }

  .ProjectDisplay {
    max-width: 100vw;
  }

  .Project p {
    padding: 0 2rem;
  }

  .ProjectTitle h3 {
    font-size: 1.5rem;
  }

  .ProjectTitle h4 {
    font-size: 1.3rem;
  }

  .GithubLink {
    margin-top: 1.5rem;
    font-size: 2rem;
  }
} 

@media (max-width: 400px) {
  .GithubLink {
    font-size: 1.5rem;
  }
}